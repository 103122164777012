document.addEventListener('DOMContentLoaded', (event) => {
    // Get all tabs
    const tabs = document.querySelectorAll('.snap-tab');

    // Add click event listener to each tab
    tabs.forEach(tab => {
        tab.addEventListener('click', function(event) {

            // Prevent the default action
            event.preventDefault();

            // Get the content id from the data-content-id attribute
            const contentId = this.dataset.contentId;

            // Remove the active class from all tabs
            tabs.forEach(tab => {
                tab.classList.remove('active');
                tab.setAttribute('aria-selected', 'false');
            });

            // Add the active class to the clicked tab
            this.classList.add('active');
            this.setAttribute('aria-selected', 'true');

            // Remove the active class from all content sections
            const contents = document.querySelectorAll('.tab-content');
            contents.forEach(content => {
                content.classList.remove('active');
            });

            // Add the active class to the content with the same id as the content id
            document.getElementById(contentId).classList.add('active');
        });
    });

    // Get all tabs
    const tabButtons = document.querySelectorAll('.snap-tab');
    // Get all tab contents
    const tabContents = document.querySelectorAll('.tab-content');

    // Add event listeners to tab buttons for arrow key navigation
    tabButtons.forEach((button, index) => {
      button.addEventListener('keydown', (e) => {
        if (e.keyCode === 37 || e.keyCode === 39) { // Left or Right arrow key
          e.preventDefault();

          const newIndex = e.keyCode === 37 ? index - 1 : index + 1;
          if (newIndex >= 0 && newIndex < tabButtons.length) {
            tabButtons[newIndex].focus();
          }
        }
      });
    });
    // Add event listeners to tab buttons for click activation
    tabButtons.forEach((button, index) => {
      button.addEventListener('click', () => {
        // Remove active class from all tab buttons and tab contents
        tabButtons.forEach((btn) => {
          btn.classList.remove('active');
          btn.setAttribute('aria-selected', 'false');
        });
        tabContents.forEach((content) => {
          content.classList.remove('active');
        });

        // Add active class to clicked tab button and corresponding tab content
        button.classList.add('active');
        button.setAttribute('aria-selected', 'true');
        tabContents[index].classList.add('active');
      });
    });

    // Add a focus event listener to each link within the tab panels
    const tabLinks = document.querySelectorAll('.tab-content a');
    tabLinks.forEach((link) => {
      link.addEventListener('keydown', (e) => {
        const currentTabContent = e.target.closest('.tab-content');
        const currentIndex = Array.from(tabContents).findIndex((content) => content === currentTabContent);
        const isLastLink = e.target === currentTabContent.lastElementChild;

        if (currentIndex !== -1 && isLastLink && e.keyCode === 9 && !e.shiftKey) { // Tab key without shift
                e.preventDefault();
                const nextIndex = (currentIndex + 1) % tabButtons.length;
            tabButtons[nextIndex].focus();

        }
      });
    });
  });